import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { moderateScale } from '@utils/scaling.web';

export const styles = StyleSheet.create({
  menuButton: {
    marginLeft: Spacing.SMALL,
    height: 40,
    width: 40,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: moderateScale(34),
    height: moderateScale(34),
    borderRadius: moderateScale(34 / 2),
    marginTop: 0,
  },
  userButton: {
    marginLeft: Spacing.BIG,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
