/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { LayoutRectangle, Pressable, View } from 'react-native';
import { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { type BottomTabBarProps } from '@react-navigation/bottom-tabs';
import {
  type NavigationState,
  type ParamListBase,
  type PartialState,
  type Route,
} from '@react-navigation/core';

import { mediumVibration } from '@utils/vibration';
import { setTabBarHeightHandler, useStore } from '@store/index';
import { DownloadCTA } from '@molecules/DownloadCTA';

import { OPTIONS, OptionsList } from './utils';
import { tabbarStyles } from './styles';

type NavigationRoute<ParamList extends ParamListBase, RouteName extends keyof ParamList> = Route<
  Extract<RouteName, string>,
  ParamList[RouteName]
> & {
  state?: NavigationState | PartialState<NavigationState>;
};

type TabbarProps = {
  navigationHandler: (
    number: number,
    position: LayoutRectangle,
    route: NavigationRoute<ParamListBase, string>
  ) => void;
  current: number;
  index: number;
  route: NavigationRoute<ParamListBase, string>;
};

const TabbarItem = ({ navigationHandler, current, index, route }: TabbarProps): JSX.Element => {
  // states
  const [position, setPosition] = useState<LayoutRectangle>();
  // constants
  const isSelected = current === index;
  const { name } = route;

  // render
  const TabItemIcon = OPTIONS[name as OptionsList].icon;

  return (
    <Pressable
      onLayout={({ nativeEvent }) => {
        setPosition(nativeEvent.layout);
      }}
      hitSlop={15}
      style={tabbarStyles.itemContainer}
      onPress={() => position && navigationHandler(index, position, route)}>
      <View style={tabbarStyles.itemContent}>
        <View style={tabbarStyles.item}>
          <TabItemIcon size={28} color={isSelected ? '#e5f68c' : '#cbc3c3'} />
        </View>
      </View>
    </Pressable>
  );
};

export const CustomTabbar = (props: BottomTabBarProps): JSX.Element => {
  // states
  const [currentPosition, setCurrentPosition] = useState<LayoutRectangle>();
  const [current, setCurrent] = useState<number>(0);
  // constants
  const { state, navigation } = props;
  const { index } = state;
  const offsetX = useSharedValue(0);
  const offsetY = useSharedValue(0);

  // hooks
  const setTabHeight = useStore(setTabBarHeightHandler);

  // functions
  const animatedStyles = useAnimatedStyle(() => {
    if (!currentPosition)
      return {
        opacity: 0,
      };
    return {
      opacity: withTiming(1, { duration: 350 }),
      transform: [
        {
          translateX: withTiming(offsetX.value + currentPosition.width - currentPosition.width / 2 - 3, {
            duration: 350,
          }),
        },
        {
          translateY: withTiming(offsetY.value + currentPosition.height + currentPosition.height / 2 + 3, {
            duration: 350,
          }),
        },
      ],
    };
  });

  function navigationHandler(
    number: number,
    position: LayoutRectangle,
    route: NavigationRoute<ParamListBase, string>
  ): void {
    mediumVibration();
    setCurrent(number);
    setCurrentPosition(position);
    const isFocused = state.index === number;

    const event = navigation.emit({
      type: 'tabPress',
      target: route.key,
      canPreventDefault: true,
    });

    if (!isFocused && !event.defaultPrevented) {
      // The `merge: true` option makes sure that the params inside the tab screen are preserved
      navigation.navigate(route.name, { merge: true });
    }
  }
  // effects
  useEffect(() => {
    setCurrent(index);
  }, [index]);

  useEffect(() => {
    if (currentPosition) {
      offsetX.value = currentPosition.x;
      offsetY.value = currentPosition.y;
    }
  }, [currentPosition]);
  // render
  return (
    <View
      onLayout={({ nativeEvent }) => setTabHeight(nativeEvent.layout.height)}
      style={tabbarStyles.container}>
      <DownloadCTA />
      {/* {currentPosition ? <Animated.View style={[tabbarStyles.back, animatedStyles]} /> : null} */}
      <View style={tabbarStyles.row}>
        {state.routes.map((route, i) => (
          <TabbarItem
            route={route}
            key={route.key}
            navigationHandler={navigationHandler}
            index={i}
            current={current}
            {...props}
          />
        ))}
      </View>
    </View>
  );
};
