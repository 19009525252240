import React from 'react';
import { Linking, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { CustomModal } from '@molecules/CustomModal';
import { useStore, menuModalSelector, toggleMenuModalHandler } from '@store/index';
import AccountItem from '@molecules/AccountItem';
import { RootStackNavigator } from 'app/navigation/types';
import { useLogoutMessage } from '@hooks/useLogoutMessage';
import links from '@constants/links';

import { menuItems } from './utils';
import { styles } from './styles';

export const MenuModal = () => {
  const menuModalOpen = useStore(menuModalSelector);
  const toggleMenu = useStore(toggleMenuModalHandler);
  const logoutMessage = useLogoutMessage();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackNavigator>>();

  const handleNavigate = (route: any) => {
    if (route === 'AboutGATO') {
      Linking.openURL(links.gatoAboutLink);
      return toggleMenu();
    }

    if (route === 'NeedHelp') {
      Linking.openURL(
        'mailto:hello@gato.us?subject=Support request from GATO&body=Hi there,\n\nThank you for contacting GATO.\n\nHow we can assist you? Feel free to write it down.\n\n## Please type your request above this line ##\n\nOur support staff will respond as soon as possible.\n\nCheers,\nGATO'
      );
      return toggleMenu();
    }

    if (route === 'Logout') {
      logoutMessage();
      return toggleMenu();
    }

    navigation.navigate(route);
    return toggleMenu();
  };

  const handleClose = () => {
    toggleMenu();
  };
  return (
    <CustomModal
      visible={menuModalOpen || false}
      onOverlayPress={handleClose}
      bottomFixed={false}
      overlayColor="transparent"
      customCardStyle={styles.positionWebWideScreen}
      customStyle={styles.background}
      translateAnimation={false}>
      <ScrollView
        contentContainerStyle={styles.innerContainer}
        style={styles.container}
        showsVerticalScrollIndicator>
        <>
          {menuItems.map(({ id, route, icon }) => (
            <AccountItem
              key={id}
              action={handleNavigate}
              text={`menu_navigation.${id}`}
              item={{
                route,
                icon,
              }}
            />
          ))}
        </>
      </ScrollView>
    </CustomModal>
  );
};
