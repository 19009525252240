/* eslint-disable @typescript-eslint/naming-convention */
import { useQuery } from 'react-query';

import { QueryKeys } from 'app/react-query/query-keys';
import { axiosAuthInstance } from '@config/api';
import { UsersCarousels } from '@interfaces/home';

async function getHomeUsersCarousel() {
  const response = await axiosAuthInstance.get<UsersCarousels>(`/users/carousel`);
  return response.data;
}

export function useHomeUsersCarousel() {
  return useQuery([QueryKeys.GET_HOME_USERS_CAROUSEL], () => getHomeUsersCarousel(), {
    refetchInterval: 1000 * 60 * 60,
  });
}
