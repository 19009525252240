import { Icon, Binoculars, House, UserCircle, UploadSimple } from '@phosphor-icons/react';

export const OPTIONS: OptionsType = {
  Home: {
    id: 'home',
    icon: House,
  },
  Explore: {
    id: 'explore',
    icon: Binoculars,
  },
  UploadGame: {
    id: 'uploadgame',
    icon: UploadSimple,
  },
  MyProfile: {
    id: 'myprofile',
    icon: UserCircle,
  },
};

export type OptionsList = 'Home' | 'Explore' | 'UploadGame' | 'MyProfile';

type OptionsType = {
  [x in OptionsList]: {
    id: string;
    icon: Icon;
  };
};
