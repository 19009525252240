import React from 'react'
import { useTranslations } from '@hooks/useTranslation';
import Button from '@atoms/Button';
import {  useNavigation } from '@react-navigation/core';
import { styles } from './styles' 

const LoginButton = () => {
	const i18n = useTranslations();
	const navigation = useNavigation();

	const handleLogin = () => navigation.navigate('Login');

  return (
    <Button
			onPress={handleLogin}
			customTextStyle={styles.buttonText}
			customStyle={styles.button}
			secondary>
			{i18n.t('desktop_navbar.login')}
    </Button>
  )
}

export default LoginButton