import { isWebResponsive } from '@constants/platform';
import { Colors, Spacing } from '@theme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    notificationButton: {
        marginLeft: isWebResponsive ? 0 : Spacing.BIG,
        height: 40,
        width: 40,
        borderRadius: isWebResponsive ? 5 : 25,
        justifyContent: 'center',
        alignItems: 'center',
    },
    notificationActiveButton: {
        backgroundColor: Colors.socialBackground,
        borderWidth: 2,
        borderColor: Colors.limeGreen
    },
    iconSize: {
        height: 24
    },
    notificationUnreads: {
        position: 'absolute',
        top: 1,
        right: 2,
        borderRadius: 1000,
        minWidth: 24,
        minHeight: 24,
        backgroundColor: Colors.purple,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 4,
    },
});
