import React, { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import {
  useStore,
  notificationsModalOpenSelector,
  toggleNotificationsModalHandler,
  menuModalSelector,
  toggleMenuModalHandler
} from '@store/index';

import { useNotificationsUnreadCount } from '@services/useNotifications';
import { View, Pressable } from 'react-native';
import CustomText from '@atoms/CustomText';
import { styles } from './styles';
import { isWebResponsive } from '@constants/platform';
import CloseIcon from '@assets/svg/CloseIcon';
import { Colors } from '@theme';
import { Bell } from '@phosphor-icons/react';

export const NotificationsButton = (): JSX.Element => {
  // store
  const notificationsModalOpen = useStore(notificationsModalOpenSelector);
  const toggleNotificationsModal = useStore(toggleNotificationsModalHandler);

  const menuModalOpen = useStore(menuModalSelector);
  const toggleMenu = useStore(toggleMenuModalHandler);

  // functions
  function handleNotificationButton(): void {
    if (menuModalOpen) {
      toggleMenu();
    }
    toggleNotificationsModal();
  }
  // hooks
  const {
    data: unreadCount,
    isLoading: isLoadingCount,
    refetch: refetechNotificationsUnreadCount
  } = useNotificationsUnreadCount();

  // effects
  useFocusEffect(
    useCallback(() => {
      refetechNotificationsUnreadCount();
    }, [refetechNotificationsUnreadCount])
  );

  // render
  return (
    <Pressable
      hitSlop={20}
      style={[styles.notificationButton, notificationsModalOpen && styles.notificationActiveButton]}
      onPress={handleNotificationButton}>
      {notificationsModalOpen ? <CloseIcon fill={Colors.limeGreen} /> : <Bell size={24} color="#A4ADC2" />}
      {!isLoadingCount && unreadCount?.totalCount > 0 && (
        <View style={styles.notificationUnreads}>
          <CustomText size="medium">{unreadCount.totalCount}</CustomText>
        </View>
      )}
    </Pressable>
  );
};
