import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { moderateScale } from '@utils/scaling.web';
import { isWebResponsive } from '@constants/platform';

export const styles = StyleSheet.create({
  button: {
    backgroundColor: Colors.limeGreen,
    marginLeft: Spacing.SMALL,
    paddingVertical: moderateScale(isWebResponsive ? 8 : 10),
    paddingHorizontal: moderateScale(isWebResponsive ? 16 : 20),
  },
  buttonText: {
    color: Colors.black,
    paddingHorizontal: isWebResponsive ? 0 : Spacing.TINY,
    paddingVertical: isWebResponsive ? 0 : Spacing.TINY / 2,
    fontSize: moderateScale(isWebResponsive ? 14 : 18),
  },
  leftGap: {
    marginLeft: Spacing.SMALL,
  },
});
