import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { moderateScale, verticalScale } from '@utils/scaling';
import { DESKTOP_CONTAINER_WIDTH } from 'app/theme/spacing';
import { SCREEN_WIDTH, WINDOW_HEIGHT, WINDOW_WIDTH } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    flex: 1,
    backgroundColor: Colors.darkGrey,
    paddingTop: moderateScale(50)
  },
  avoiding: {
    backgroundColor: Colors.darkGrey,
    width: '100%',
    flex: 1
  },
  inner: {
    flex: 1,
    width: '100%',
    alignSelf: 'center'
  },
  desktopContent: {
    flex: 1,
    width: '100%'
  },
  btn: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 0
  },
  mb20: {
    marginBottom: verticalScale(20)
  },
  verticallyCentered: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  horizontallyCentered: {
    width: '100%',
    maxWidth: DESKTOP_CONTAINER_WIDTH,
    paddingHorizontal: SCREEN_WIDTH >= DESKTOP_CONTAINER_WIDTH ? 0 : Spacing.LATERALSMALL,
    alignSelf: 'center',
    flexGrow: 1
  },
  backgroundGleam: {
    position: 'absolute',
    right: 0,
    top: verticalScale(70)
  },
  gleam1: {
    position: 'absolute',
    right: moderateScale(-235),
    top: verticalScale(27),
    backgroundColor: Colors.glow,
    opacity: 0.1,
    height: verticalScale(408),
    width: verticalScale(408),
    filter: 'blur(177.464px)',
    borderRadius: verticalScale(204),
    zIndex: -1
  },
  // eslint-disable-next-line react-native/no-color-literals
  gleam2: {
    position: 'absolute',
    right: moderateScale(-150),
    bottom: verticalScale(175),
    backgroundColor: 'rgba(181, 180, 255, 0.7)',
    height: verticalScale(235),
    width: verticalScale(235),
    filter: 'blur(177.464px)',
    borderRadius: verticalScale(118),
    zIndex: -1
  },
  gradient: {
    zIndex: 0,
    width: WINDOW_WIDTH * 1.1,
    height: WINDOW_HEIGHT * 1.1,
    position: 'absolute'
  }
});
