import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { moderateVerticalScale } from '@utils/scaling';
import { DESKTOP_CONTAINER_WIDTH } from 'app/theme/spacing';
import { moderateScale } from '@utils/scaling.web';
import { isWebResponsive } from '@constants/platform';

export const styles = StyleSheet.create({
  outerContainer: {
    backgroundColor: Colors.black,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: isWebResponsive ? 60 : 80,
    borderBottomWidth: 0,
    width: '100%',
    maxWidth: DESKTOP_CONTAINER_WIDTH,
    paddingHorizontal: Spacing.BIG,
    alignSelf: 'center',
    justifyContent: isWebResponsive ? 'space-between' : 'flex-start',
    backgroundColor: isWebResponsive ? Colors.darkGrey : 'transparent',
  },
  adContainer: {
    marginTop: Spacing.SMALL,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 0,
    width: '100%',
    maxWidth: DESKTOP_CONTAINER_WIDTH,
    paddingHorizontal: Spacing.BIG,
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: isWebResponsive ? Colors.darkGrey : 'transparent'
  },
  logoImage: {
    marginRight: Spacing.SMALL,
  },
  logoImageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  linkButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: Spacing.TINY,
    marginLeft: Spacing.SMALL,
  },
  btnSecondary: {
    backgroundColor: Colors.blackTwo,
    borderRadius: 10,
    paddingVertical: moderateScale(10),
    paddingHorizontal: moderateScale(20),
  },
  exploreIcon: {
    transform: [{ scale: 0.9 }],
  },
  buttonText: {
    paddingHorizontal: Spacing.TINY,
    paddingVertical: Spacing.TINY / 2,
  },
  separator: {
    flex: 1,
  },
  leftGap: {
    marginLeft: Spacing.SMALL,
  },
  userButton: {
    marginLeft: Spacing.BIG,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropDownMenu: {
    position: 'absolute',
    top: moderateVerticalScale(100) / 2,
    right: 0,
  },
  transparent: {
    backgroundColor: Colors.transparentBlack,
  },
  transparentGradient: {
    width: '200%',
    height: 100,
    position: 'absolute',
    alignSelf: 'center',
  },
  hover: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  avatar: {
    width: moderateScale(34),
    height: moderateScale(34),
    borderRadius: moderateScale(34 / 2),
    marginTop: 0,
  },
  search: {
    flex: 1,
  },
});
